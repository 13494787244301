/* Radiobuttons */
$(document).on('change', '.__radio input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radio')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radio input:checked')
    .parent()
    .addClass('__checked');

/* Checkboxes */
$(document).on('change', '.__checkbox input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');

/* Focus state */
$(document).ready(function () {
    $('.field_wrap.__radio input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
});

// // File upload
// $(function() {
//     // File preview
//     var fileInputs = document.querySelectorAll('.field_wrap.__file .input_wrap input');

//     fileInputs.forEach((fileInput) => {
//         fileInput.addEventListener('change', updateImageDisplay);
//     });

//     function updateImageDisplay() {
//         var fileParent = this.closest('.field_wrap.__file');
//         var filePreview = fileParent.querySelector('.preview_wrap');

//         fileParent.classList.add("active");

//         while (filePreview.firstChild) {
//             fileParent.classList.remove("active");
//             filePreview.removeChild(filePreview.firstChild);
//         }

//         var files = this.files;

//         if (!files.length) {
//             var fileItem = document.createElement('p');
//             fileItem.textContent = '';
//             filePreview.appendChild(fileItem);
//         } else {
//             var fileList = document.createElement('ol');
//             filePreview.appendChild(fileList);

//             for (var i = 0; i < files.length; i++) {
//                 var fileListItem = document.createElement('li');
//                 var fileItem = document.createElement('p');

//                 fileItem.textContent = files[i].name;
//                 fileListItem.appendChild(fileItem);
//                 fileList.appendChild(fileListItem);
//             }
//         }
//     }

//     // File drag and drop
//     var fileDrops = document.querySelectorAll('.field_wrap.__file');

//     // Create change event
//     const eventChange = new Event("change");

//     fileDrops.forEach((fileDrop) => {
//         fileDrop.ondragover = function (e) {
//             e.preventDefault();
//             this.classList.add('dragover');
//         };
//         fileDrop.ondragleave = function (e) {
//             e.preventDefault();
//             this.classList.remove('dragover');
//         };
//         fileDrop.ondrop = function (e) {
//             e.preventDefault();
//             this.classList.remove('dragover');
//             this.querySelector('input').files = e.dataTransfer.files;
//             this.querySelector('input').dispatchEvent(eventChange);
//         }
//     });
// });

// Date picker
flatpickr(".__date input", {
    altInput: true, // creates new visual input
    altFormat: "F j, Y", // sets the format to be more friendly, i.e. Thursday 8th April 2021
    dateFormat: "Y-m-d", // sets the format to match server-side data i.e. 2021-04-08
});

// Time picker
flatpickr(".__time input", {
    enableTime: true,
    noCalendar: true,
    time_24hr: true,
    altInput: true, // creates new visual input
    altFormat: "F j, Y", // sets the format to be more friendly, i.e. Thursday 8th April 2021
    dateFormat: "Y-m-d", // sets the format to match server-side data i.e. 2021-04-08
});

// Date and time picker
flatpickr(".__datetime input", {
    enableTime: true,
    altInput: true, // creates new visual input
    altFormat: "F j, Y", // sets the format to be more friendly, i.e. Thursday 8th April 2021
    dateFormat: "Y-m-d", // sets the format to match server-side data i.e. 2021-04-08
});

// Default select with no search
$('.__select select').each(function () {
    $(this).select2({
        containerCssClass: 'select2-css',
        minimumResultsForSearch: Infinity,
    })
});

// Select with search
$('.__selectsearch select').each(function () {
    $(this).select2({
        containerCssClass: 'select2-css',
    })
});


// Select with images
function formatSelectWithImage(select) {
    var select = select.element;
    var imagePath = "/images/flags/";
    var injectImage = $('<span class="select2-template-image"><img src="' + imagePath + $(select).data('image') +
        '">' + $(select).text() + '</span>');
    return injectImage;
};
$('.__selectwithimages select').each(function () {
    var selectId = $(this).parent();
    $(this).select2({
        containerCssClass: 'select2-css',
        templateResult: formatSelectWithImage,
        templateSelection: formatSelectWithImage
    });
});